import {
	MODAL_CHECKOUT_SLUG_ERROR,
	SAVE_SITE_NOTIFICATION_ID,
} from '@/constants';
import { OPEN_MODAL } from '@/store/builder/gui';
import { useUserAuthorizationState } from '@/use/useUserAuthorizationState';
import { SitePages } from '@hostinger/builder-schema-validator';
import {
	computed,
	nextTick,
	ref,
} from 'vue';
import EventLogApi from '@/api/EventLogApi';
import { useStore } from 'vuex';
import { usePublish } from '@/use/usePublish';
import { useNotifications } from '@/use/useNotifications';

export const useSitePublish = () => {
	const {
		handlePublish,
		handleRepublish,
	} = usePublish();

	const {
		state,
		getters,
		dispatch,
	} = useStore();

	const { removeNotificationById } = useNotifications();

	const { isUserPayToPublish } = useUserAuthorizationState();
	const zyroDomain = computed(() => state.zyroDomain);

	const isP2PLockPopupVisible = ref(false);

	const getCheckoutSlugPageId = () => {
		const pages: SitePages = getters.sitePages;
		const checkoutSlugPageId = Object.keys(pages).find((pageId) => pages[pageId].slug === 'checkout') || '';

		return checkoutSlugPageId;
	};

	const publishSite = async () => {
		const checkoutSlugPageId = getCheckoutSlugPageId();

		if (checkoutSlugPageId && checkoutSlugPageId !== 'home') {
			dispatch(`gui/${OPEN_MODAL}`, {
				name: MODAL_CHECKOUT_SLUG_ERROR,
				settings: {
					pageId: checkoutSlugPageId,
				},
			});

			return;
		}

		if (isUserPayToPublish.value) {
			isP2PLockPopupVisible.value = true;

			EventLogApi.logEvent({
				eventName: 'website_builder.pay_2_publish.go_live',
			});

			return;
		}

		removeNotificationById(SAVE_SITE_NOTIFICATION_ID);

		dispatch('unselectCurrentElement');

		// Some elements update after being unselected
		// this ensures that site data has the latest changes before publishing
		await nextTick();

		dispatch('updateFonts');

		if (zyroDomain.value) {
			await handleRepublish();
		} else {
			await handlePublish();
		}
	};

	return {
		publishSite,
		isP2PLockPopupVisible,
	};
};
